import React, { Component, useContext } from 'react'
import { Button, Icon, Label, Modal, Table, Segment, Input, TextArea, Form, Header, Image, Dimmer, Loader, Message } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import UpdateStatus from './UpdateStatus';

const MessageExampleDismissibleBlock = (prop) => {
  const store = useContext(StoreContext)

  const [open, setOpen] = React.useState(false)

  console.log(`status = ${store.getHistoryItemStatus(prop.sid)}`)
  
  

  //if(store.getHistoryItemStatus(prop.sid))

  const handleShopItems = () => {
    console.log(`prop.num: ${prop.num}`)
    store.selectHistory(prop.num)
  }

  const closeWindow = () => {
    store.showMsg(false)
    store.setDescription('')
    setOpen(false)
  }

  const handleDismiss = () => {
    store.showError(false)
    store.setErrorMsg('')
  }

  const handleMsgDismiss = () => {
      store.showMsg(false)
  }

  const updateStatus = (key) => {
    (async () => {
      var status = ''

      if(store.shoppingStatus === '1'){
        status = 'canceled'
      }

      if(store.shoppingStatus === '2'){
        status = 'approved' 
      }

      console.log(`key = ${key}`)

      var temp_url = `https://e-shop.onrender.com/price_list/${key}`

      console.log(temp_url)
      const rawResponse = await fetch(temp_url, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${store.token}`
        },
        body: JSON.stringify({
            "status": status,
        })
      });
      const res = await rawResponse
      if(!res.ok){
        res.text().then(text => {
          console.log(text)
          var jsObj = JSON.parse(text)
          store.setErrorMsg(jsObj['detail'])
          store.showError(true)
        })

      }
      else{
        const content = await rawResponse.json();
        console.log(content);

        store.updateHistoriesAdmin(key, status)
        store.showMsg(true)
      }
    })();
  }


  return useObserver(() => (
    <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                store.editCategoryOf()
                setOpen(true)
            }}
            open={open}
            trigger={
              <Button positive onClick={() => handleShopItems()}>Показати</Button>
            }
            >
            <Modal.Header>Shopping List</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                {store.isLoading &&
                    <Segment  vertical style={{ margin: '1em 0em 0em', padding: '5em 0em' }}>
                        <Dimmer active inverted>
                        <Loader size='large'>Завантаження</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/paragraph.png' />
                    </Segment>
                }
                {!store.isLoading &&
                <>
                {store.isAdmin &&
                  <Form error>
                      {store.hasMsg &&
                          <Message
                              onDismiss={() => {handleMsgDismiss()}}
                              header='Успішно!!!'
                          />
                      }
                      {store.hasError &&
                          <Message
                              onDismiss={() => {handleDismiss()}}
                              error
                              header='Error'
                              content={store.errorMsg}
                          />
                      }
                  </Form>
                }
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Ім'я</Table.HeaderCell>
                      <Table.HeaderCell>Число</Table.HeaderCell>
                      <Table.HeaderCell>Ціна</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {store.historyItems.map((value, index) => {
                      return <Table.Row>
                      <Table.Cell>
                        <Header as='h4' image>
                          <Image src={value.image} rounded size='medium' />
                          <Header.Content>
                            {store.isAdmin ? value.id:''}{store.isAdmin ? '-':''}{value.name}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>{value.count}</Table.Cell>
                      <Table.Cell>{new Intl.NumberFormat('ja-UA', {}).format(value.price)}</Table.Cell>
                    </Table.Row>
                    })}
                  </Table.Body>

                  
                </Table>
                <Segment>Опис: {store.description}</Segment>
                <Segment>Всього предметів: {store.countOfHistoryItems}; Загальна сума: <span>&#8372;</span>{store.countOfHistoryPrice}</Segment>
                {store.isAdmin &&
                  <Segment>
                    <UpdateStatus sid={prop.sid}/>
                  </Segment>
                }
                
                </>
                  }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => closeWindow()}
                >
                Закрити
                </Button>  
                {store.isAdmin &&
                  <>
                    <Button
                        content="Оновити статус"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => updateStatus(prop.sid)}
                        positive
                    />
                  </>
                }     
            </Modal.Actions>
        </Modal>

  ))
}

export default MessageExampleDismissibleBlock