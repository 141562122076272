import React, { useContext } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const options = [
  { key: 'p', text: 'ціна', value: '3' },
  { key: 'c', text: 'код', value: '2' },
  { key: 'n', text: "ім'я", value: '1' },
]

const DropdownExampleUpwardInline = () => {
  const store = useContext(StoreContext)

  console.log(store.sortBy)

  const handleChange = (e, {value}) => {
    console.log(value)
    store.setSortBy(value)
  }

  return useObserver(() => (
    <Dropdown fluid placeholder='Сортувати за' onChange={handleChange} selection options={options} value={store.sortBy}/>

  ))
  }

export default DropdownExampleUpwardInline