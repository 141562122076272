import React, { useContext, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const options = [
  { key: 'a', text: 'canceled', value: '1' },
  { key: 'd', text: 'approved', value: '2' },
]

const DropdownExampleUpwardInline = (prop) => {
  const store = useContext(StoreContext)

  useEffect(() => {
    console.log('DROP-DOWN: mount it!');
    if(store.getHistoryItemStatus(prop.sid) === 'canceled' || store.getHistoryItemStatus(prop.sid) === 'approved'){
      console.log('canceled or approved')
      if(store.getHistoryItemStatus(prop.sid) === 'canceled'){
        store.setStaus('1')
      }
      else{
        store.setStaus('2')
      }
    }
    else{
      console.log('sent')
      store.setStaus('0')
    }

  }, []);

  

  const handleChange = (e, {value}) => {
    console.log(value)
    store.setStaus(value)
  }

  return useObserver(() => (
    <Dropdown fluid placeholder='Status' selection onChange={handleChange} options={options} value={store.shoppingStatus}/>
  ))
  }

export default DropdownExampleUpwardInline