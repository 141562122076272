import React, { useContext, useEffect } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Loader,
  Dimmer,
  Icon,
  Label
} from 'semantic-ui-react'
import Login from './Login';
import MainView from './MainView';
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import ShopButton from './ShopButton';
import ShopHistory from './ShopHistory';
import AboutUs from './AboutUs';

const loadB = (store) => {
  fetch(
    "https://e-shop.onrender.com/category/")
    .then((res) => {
      if(!res.ok){
        console.log("ERROR")
      }
        console.log(res.status)
        return res.json()
      })
    .then((json) => {
      console.log(json)
      store.addCategories(json)

      var tempCategory = store.categories[0]

      console.log(tempCategory)
      
      store.setSelectedCategory(tempCategory.id)
      loadC1(store, tempCategory.id)

      //loadC(store, tempCategory)
    })
}

const loadC1 = (store, categoryID) => {
      store.isLoading = true
      fetch(`https://e-shop.onrender.com/product/category/${categoryID}`)
            .then((res) => {
              if(!res.ok){
                console.log("ERROR")
                store.isLoading = false
              }
                console.log(res.status)
                return res.json()
              })
            .then((json) => {
              console.log(json)
              store.addProducts(json)
              store.isLoading = false
      })
    
    }

const loadC = (store, tempCategory) => {
  fetch(`https://e-shop.onrender.com/product/${tempCategory.id}`)
        .then((res) => {
          if(!res.ok){
            console.log("ERROR")
          }
            console.log(res.status)
            return res.json()
          })
        .then((json) => {
          console.log(json)
          store.addProducts(json)
          tempCategory.loaded = true
  })

}

const FixedMenuLayout = () => {
  const store = useContext(StoreContext)

  useEffect(() => {
    console.log('mount it!');
    //store.setLastUpdate(update)
    fetch(
      "https://e-shop.onrender.com/updated/")
      .then((res) => {
        if(!res.ok){
          console.log("ERROR")
        }
          console.log(res.status)
          return res.json()
        })
      .then((json) => {
        store.setLastUpdate(json)
        loadB(store) 
      })

  }, []);

    return useObserver(() =>  (
  <div>
    <Menu fixed='top' inverted>
      <Container>
        <Menu.Item as='a' header>
        <Button color='google plus'>
          ЮТАС
        </Button>
        </Menu.Item>
        <AboutUs />
        <Menu.Item as='a' href='https://drive.google.com/drive/folders/1ckBRBx-S5xtc3ZLSx50BhCaks9Cx_MTk?usp=sharing'>Ціни</Menu.Item>

        {store.showMain && store.token.length === 0 && !store.isVerified &&
          <Menu.Item position='right'>
            <Button as='a' inverted
              onClick={() => {store.openLogin()}}
            >
              Увійти
            </Button>
          </Menu.Item>
        }
        {store.showMain && store.token.length > 0 &&
          <Menu.Item position='right'>
            {!store.isAdmin &&
              <ShopButton />
            }
            
            <ShopHistory />
            <Button as='a' inverted style={{margin: '0em 0.1em', }}
                onClick={() => {store.setLogout()}}
              >
                Вийти
            </Button>
          </Menu.Item>
        }

      </Container>
    </Menu>

    {((store.showLogin || store.showSingup) && !store.isLoading) &&
      <Login />
    }
    {(store.showMain && !store.isLoading) &&
      <MainView />
    }
    {store.isLoading &&
      <Segment  vertical style={{ margin: '8em 0em 0em', padding: '25em 0em' }}>
        <Dimmer active inverted>
          <Loader size='large'>Завантаження</Loader>
        </Dimmer>

        <Image src='/images/wireframe/paragraph.png' />
      </Segment>
    }
    
      

    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>

        <Grid divided inverted stackable>
          <Grid.Column width={4} textAlign='left'>
            <Header inverted as='h4' content="Зв'яжіться з нами:" />
          </Grid.Column>
          <Grid.Column width={4}>
            <Header inverted as='h4' content='тел.: +380504947012,' />
            <Header inverted as='h4' content='+380554943363,' />
            <Header inverted as='h4' content='+380508191526,' />
            <Header inverted as='h4' content='+380952771015,' />
            <Header inverted as='h4' content='+380565448123,' />
            <Header inverted as='h4' content='+380682921326' />
          </Grid.Column>
          <Grid.Column width={4}>
            <Header inverted as='h4' content='пошта: johnnk1979@gmail.com' />
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            <Header inverted as='h4' content='viber: +380504947012' />
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            <Header inverted as='h4' content='copy right @Utas' />
            
          </List.Item>
        </List>
      </Container>
    </Segment>
  </div>
))
}

export default FixedMenuLayout