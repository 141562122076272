import React, { useContext } from 'react'
import { Input } from 'semantic-ui-react'
import { StoreContext } from './index';



const InputExampleFluid = () => {
  const store = useContext(StoreContext)

  const handleChange = (e, {value}) => {
    console.log(value)
    store.setSearchText(value)
  }

  return (
    <Input fluid icon='search' onChange={handleChange} placeholder='Шукати...' />
  )

}
  


export default InputExampleFluid