import React, { Component, useContext } from 'react'
import { Button, Icon, Label, Modal, Table, Segment, Input, TextArea, Form, Header, Image, Dimmer, Loader, Message } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import BuyButton from './BuyButton'

const MessageExampleDismissibleBlock = () => {
  const store = useContext(StoreContext)

  const [open, setOpen] = React.useState(false)

  const handleShopItems = () => {
    console.log('-shop-items-')
  }

  const handleDismiss = () => {
    store.showError(false)
    store.setErrorMsg('')
  }

  const handleMsgDismiss = () => {
      store.showMsg(false)
  }

  const closeWindow = () => {
    store.showMsg(false)
    store.setDescription('')
    setOpen(false)
  }

  const addItem = (id) => {
    store.addShopItem(id)
  }

  const removeItem = (id) => {
    store.removeShopItem(id)
  }

  const cleanAll = () => {
    store.cleanShoppingList()
  }

  const handleTextAreaChange = (e, {value}) => {
    console.log(value)
    store.setDescription(value)
  }

  const buyOnline = () => {
    
    //401
    (async () => {
          var temp_itemps = JSON.stringify({description: store.description, items: store.shopItems})
          console.log(temp_itemps)
          const rawResponse = await fetch(`https://e-shop.onrender.com/price_list/`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${store.token}`
            },
            body: JSON.stringify({
                "items": temp_itemps,
            })
          });
          const res = await rawResponse
          if(!res.ok){
            res.text().then(text => {
              console.log(text)
              var jsObj = JSON.parse(text)
              store.setErrorMsg(jsObj['detail'])
              store.showError(true)
            })

          }
          else{
            const content = await rawResponse.json();
            console.log(content);

            store.showMsg(true)
            store.cleanShoppingList()
          }
        })();
  }

  return useObserver(() => (
    <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                store.editCategoryOf()
                setOpen(true)
            }}
            open={open}
            trigger={
              <Button as='div' labelPosition='right' onClick={() => handleShopItems()}>
                <Button color='orange'>
                  <Icon name='cart' />
                  Предмети
                </Button>
                <Label as='a' basic color='red' pointing='left'>
                  {store.countOfShopItems} | <span>&#8372;</span>{store.countOfTotalPrice}
                </Label>
              </Button>
            }
            >
            <Modal.Header>Список покупок</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                {store.isLoading &&
                    <Segment  vertical style={{ margin: '1em 0em 0em', padding: '5em 0em' }}>
                        <Dimmer active inverted>
                        <Loader size='large'>Завантаження</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/paragraph.png' />
                    </Segment>
                }
                {!store.isLoading &&
                <>
                  <Form error>
                      {store.hasMsg &&
                          <Message
                              onDismiss={() => {handleMsgDismiss()}}
                              header='Успішно!!!'
                          />
                      }
                      {store.hasError &&
                          <Message
                              onDismiss={() => {handleDismiss()}}
                              error
                              header='Error'
                              content={store.errorMsg}
                          />
                      }
                  </Form>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Ім'я</Table.HeaderCell>
                      <Table.HeaderCell>Кількість</Table.HeaderCell>
                      <Table.HeaderCell>Ціна</Table.HeaderCell>
                      <Table.HeaderCell>Редагувати</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {store.shopItems.map((value, index) => {
                      return <Table.Row>
                      <Table.Cell>
                        <Header as='h4' image>
                          <Image src={value.image} rounded size='medium' />
                          <Header.Content>
                            {value.name}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>{value.count}</Table.Cell>
                      <Table.Cell>{new Intl.NumberFormat('ja-UA', {}).format(value.price)}</Table.Cell>
                      <Table.Cell>
                      <Button.Group>
                        <Button positive onClick={() => addItem(value.id)}>Додати</Button>
                        <Button.Or />
                        <Button onClick={() => removeItem(value.id)}>Видалити</Button>
                      </Button.Group>
                      </Table.Cell>
                    </Table.Row>
                    })}
                  </Table.Body>

                  
                </Table>
                <Form>
                  <TextArea placeholder='Опис...' onChange={handleTextAreaChange}/>
                </Form>

                <Segment>Всього предметів: {store.countOfShopItems}; Загальна сума: <span>&#8372;</span>{store.countOfTotalPrice}</Segment>
                </>
                  }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => closeWindow()}
                >
                Закрити
                </Button>
                {store.shopItems.length > 0 &&
                  <>
                    <Button onClick={() => cleanAll()}
                    >
                    Видалити всі елементи
                    </Button>
                    <BuyButton
                    />
                  </>
                }
                
            </Modal.Actions>
        </Modal>

  ))
}

export default MessageExampleDismissibleBlock