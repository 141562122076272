import React from 'react'
import { Button, Modal, Menu, Table, Segment, Image } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
//https://ibb.co/02qqgL8
//https://i.ibb.co/bm11SVq/IMG-20220423-162340.jpg
function ModalExampleShorthand() {
    const [open, setOpen] = React.useState(false)

    return useObserver(() => (
    <Modal
        onClose={() => setOpen(false)}
        onOpen={() => {
            setOpen(true)
        }}
        open={open}

      trigger={<Menu.Item as='a'>Про нас</Menu.Item>}
    >
        <Modal.Header>Про нас</Modal.Header>
        <Modal.Content>
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Зв'яжіться з нами</Table.HeaderCell>
                    <Table.HeaderCell>#</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>тел.:</Table.Cell>
                    <Table.Cell textAlign='center'>+380504947012, +380554943363, +380508191526, +380952771015, +380565448123, +380682921326</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>пошта: </Table.Cell>
                    <Table.Cell textAlign='center'>johnnk1979@gmail.com</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>viber:</Table.Cell>
                    <Table.Cell textAlign='center'>+380504947012</Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>
            <Segment>
              <Image src='https://i.ibb.co/bm11SVq/IMG-20220423-162340.jpg' size='medium' centered />
              <p align='center'>ул. Промышленная, на територии оптового рынка «УПТК», Новая Каховка, Украина</p>
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button color='red' onClick={() => setOpen(false)}>
                Закрити
            </Button>       
        </Modal.Actions>
    </Modal>
  ))
}

export default ModalExampleShorthand