import React, { useContext } from 'react'
import { Button } from 'semantic-ui-react'
import { StoreContext } from './index';

const ButtonExampleFluid = () => {
    const store = useContext(StoreContext)

    return(
        <Button fluid onClick={() => {store.applySort()}}>Застосувати</Button>
    )
}

export default ButtonExampleFluid