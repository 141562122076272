import React, {useContext, useState} from 'react'
import { Button, Modal, Segment, Dimmer, Loader, Image, Form, Message } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

function ModalExampleShorthand() {
    const store = useContext(StoreContext)

    const [open, setOpen] = React.useState(false)

    const [code, setCode] = useState('');

    const onChangeHandler = event => {
        setCode(event.target.value);
    }

    const handleDismiss = () => {
        store.showError(false)
        store.setErrorMsg('')
    }
    
    const handleMsgDismiss = () => {
        store.showMsg(false)
    }

    const handleSubmit = () => {

        console.log(code)
        verifyCode()
    }

    const handleClose = () => {
        setCode('');
        setOpen(false)
    }

    const verifyCode = () => {
    
        //401
        (async () => {
              const rawResponse = await fetch(`https://e-shop.onrender.com/login/verifyemail/${code}`, {
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${store.token}`
                }
              });
              const res = await rawResponse
              if(!res.ok){
                res.text().then(text => {
                  console.log(text)
                  var jsObj = JSON.parse(text)
                  store.setErrorMsg(jsObj['detail'])
                  store.showError(true)
                })
    
              }
              else{  
                store.setVerified(true)  
                store.showMsg(true)
              }
            })();
      }

    const buyOnline = () => {
    
        //401
        (async () => {
              var temp_itemps = JSON.stringify({description: store.description, items: store.shopItems})
              console.log(temp_itemps)
              const rawResponse = await fetch(`https://e-shop.onrender.com/price_list/`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${store.token}`
                },
                body: JSON.stringify({
                    "items": temp_itemps,
                })
              });
              const res = await rawResponse
              if(!res.ok){
                res.text().then(text => {
                  console.log(text)
                  var jsObj = JSON.parse(text)
                  store.setErrorMsg(jsObj['detail'])
                  store.showError(true)
                })
    
              }
              else{
                const content = await rawResponse.json();
                console.log(content);
    
                store.showMsg(true)
                store.cleanShoppingList()
              }
            })();
      }

    return useObserver(() => (
    <Modal
        onClose={() => setOpen(false)}
        onOpen={() => {
            if(!store.isVerified){
                setOpen(true)
            }
            else{
                buyOnline()
            }            
        }}
        open={open}

      trigger={<Button
                        content="Надішліть запит на покупку"
                        labelPosition='right'
                        icon='checkmark'
                        positive
                    />}
    >
        <Modal.Header>Перевірка</Modal.Header>
        <Modal.Content>
            {store.isLoading &&
                <Segment  vertical style={{ margin: '1em 0em 0em', padding: '5em 0em' }}>
                    <Dimmer active inverted>
                        <Loader size='large'>Завантаження</Loader>
                    </Dimmer>

                    <Image src='/images/wireframe/paragraph.png' />
                </Segment>
            }
            {!store.isLoading &&
                <>
                <Form error>
                      {store.hasMsg &&
                          <Message
                              onDismiss={() => {handleMsgDismiss()}}
                              header='Успішно!!!'
                          />
                      }
                      {store.hasError &&
                          <Message
                              onDismiss={() => {handleDismiss()}}
                              error
                              header='Error'
                              content={store.errorMsg}
                          />
                      }
                  </Form>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Input
                            label='Введіть код підтвердження, який був надісланий на вашу електронну пошту'
                            placeholder='code'
                            name='code'
                            onChange={onChangeHandler}
                            value={code}
                        />
                        
                    </Form.Group>
                    <Form.Button content='Submit' />
                  </Form>
                </>
            }
            
        </Modal.Content>
        <Modal.Actions>
            <Button color='red' onClick={() => handleClose()}>
                Закрити
            </Button>       
        </Modal.Actions>
    </Modal>
  ))
}

export default ModalExampleShorthand