import React, { useContext } from 'react'
import { Button, Form, Grid, Header, Divider, Message, Segment } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import MessageError from './MessageError';
import MessagePositiv from './MessagePositiv';

const LoginForm = () => {
    const store = useContext(StoreContext)

    const handleChange = (e, { name, value }) => {
      if(name === 'email'){
        store.setEmail(value)
      } 
      if(name === 'password'){
        store.setPassword(value)
      } 
      if(name === 'password2'){
        store.setPassword2(value)
      }
    }

    function getMainText() {
        if (store.showLogin){
            return 'Увійти'
        }
        else{
            return 'Зареєструватися'
        }
    }

    function getSecondText() {
        if (store.showLogin){
            return 'Нове для нас? - Зареєструватися'
        }
        else{
            return 'Повернутися до Увійти'
        }
    }

    const singUp = (_user, _pass, _pass2) => {
      (async () => {
        store.showMsg(false)
        store.showError(false)
        store.setErrorMsg('')
        const rawResponse = await fetch('https://e-shop.onrender.com/user/', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'username': _user,
            "email": _user,
            'password': _pass,
            'second_password': _pass2
          })
        });
        const res = await rawResponse
        if(!res.ok){
          res.text().then(text => {
            console.log(text)
            var jsObj = JSON.parse(text)
            store.setErrorMsg(jsObj['detail'])
            store.showError(true)
          })
          store.setEmail('')
          store.setPassword('')
          store.setPassword2('')
        }
        else{
          //store.setEmail('')
          //store.setPassword('')
          store.setPassword2('')

          const json = await res.json();
          console.log(json);

          store.showMsg(true)
          store.setErrorMsg('Ви зареєстровані. Тепер вам потрібно увійти та перевірити свою електронну пошту на наявність коду підтвердження (його можна використовувати, коли ви збираєтеся надіслати свій список покупок). ')

          store.flipSingUpLogin()
        }
      })();
    }

    const logIn = (_user, _pass) => {
      var details = {
        'username': _user,
        'password': _pass,
        'grant_type': 'password'
      };
      
      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      
      fetch('https://e-shop.onrender.com/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
      }).then(res => {
        if(!res.ok){
          console.log(res.statusText)
          store.setErrorMsg(res.statusText)
          store.showError(true)

          store.setEmail('')
          store.setPassword('')
          store.setPassword2('')
        }

        return res.json()
      }).then((json) => {
        store.setEmail('')
        store.setPassword('')
        store.setPassword2('')
        
        console.log(json['access_token'], json['verified'])
        //store.setToken(json['access_token'])
        //store.setVerified(json['verified'])

        if(json['role'] === 'admin'){
          store.setAdmin(json['access_token'], json['verified'])
        }
        if(json['role'] === 'user'){
          store.setUser(json['access_token'], json['verified'])
        }

        store.closeLogin()
      });
    }

    const proceedLoginOrSingup = () => {
      //access_token
      console.log('--------')
      if(store.showSingup){
        singUp(store.email, store.password, store.password2)
      }
      else{
        logIn(store.email, store.password);
      }
      
    }


    return useObserver(() => (
  <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
      {store.hasError &&
        <MessageError />
      }
      {store.hasMsg &&
        <MessagePositiv />
      }
      <Header as='h2' color='teal' textAlign='center'>
         {getMainText()} у свій обліковий запис
      </Header>
      <Form size='large'>
        <Segment stacked>
          <Form.Input
            name='email' 
            value = {store.email}
            fluid icon='user' 
            iconPosition='left' 
            placeholder='E-mail address'
            onChange={handleChange}
           />
          <Form.Input
            name='password'
            value = {store.password}
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
            onChange={handleChange}
          />
          {store.showSingup &&
          <Form.Input
            name='password2'
            value = {store.password2}
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Confirm Password'
            type='password'
            onChange={handleChange}
          />
          }

          <Button color='teal' fluid size='large' onClick={() => {proceedLoginOrSingup()}}>
           {getMainText()}
          </Button>
          <Divider horizontal></Divider>
          <Button basic color='red' fluid size='large' onClick={() => {store.closeLogin()}}>
            Скасувати
          </Button>
        </Segment>
      </Form>
      <Message>
         <a onClick={() => store.flipSingUpLogin()}>{getSecondText()}</a>
      </Message>
    </Grid.Column>
  </Grid>
))}

export default LoginForm