import { makeAutoObservable} from 'mobx'


class History{
    number = ''
    items = ''
    created = ''
    status = ''
    description = ''
    key = ''

    constructor(_obj) {
        // Call it here
        makeAutoObservable(this)
        this.number = _obj.number
        this.items = _obj.items
        this.created = _obj.created
        this.status = _obj.status
        this.description = _obj.description
        if('key' in _obj){
            this.key = _obj.key
        }
    }

    updateStatus(_status){
        this.status = _status
    }
}

class Product{
    id = null;
    name = "";
    description = "";
    price = 0;
    image = "";
    show_price = true;
    is_available = true;
    stars = 0;
    category_id = "";

    constructor(_id, _obj) {
        // Call it here
        makeAutoObservable(this)
        this.id = _id
        this.name = _obj.name

        this.description = _obj.description
        this.price = _obj.price
        this.image = _obj.image
        this.show_price = _obj.show_price
        this.is_available = _obj.is_available
        this.stars = _obj.stars
        this.category_id = _obj.category_id
    }

    updateProduct(_obj){
        this.name = _obj.name

        this.description = _obj.description
        this.price = _obj.price
        this.image = _obj.image
        this.show_price = _obj.show_price
        this.is_available = _obj.is_available
        this.stars = _obj.stars
        this.category_id = _obj.category_id
    }
}

class ShopItem{
    id = null;
    name = "";
    price = 0;
    image = "";
    category_id = "";
    count = 1;

    constructor(_obj) {
        // Call it here
        makeAutoObservable(this)
        this.id = _obj.id
        this.name = _obj.name
        this.price = _obj.price
        this.image = _obj.image
        this.category_id = _obj.category_id
        if('count' in _obj){
            this.count = _obj.count
        }
    }

    updateCount(){
        this.count++
    }

    removeItem(){
        this.count--
    }
}

class Category{
    id = null;
    name = "";
    loaded = false;

    constructor(_id, _name) {
        // Call it here
        makeAutoObservable(this)
        this.id = _id
        this.name = _name
    }

    updateCategory(_obj){
        this.name = _obj.name
    }

    setLoaded(val){
        this.loaded = val
    }

}

class Store {

    categories = []

    products = []

    selectedProducts = []

    selectedProductsOnPage = []

    shopItems = []

    likesCount = 12

    didMount = 0

    newCommentCount = 0

    showLogin = false
    showMain = true
    showSingup = false

    token = ""
    isVerified = false
    isAdmin = false

    isLoading = false

    lastUpdate = ""

    currentPage = 1

    itemsPerPage = 12

    testPages = 113

    lastPage = Math.ceil(this.selectedProducts.length/this.itemsPerPage)

    selectedCategory = ""

    searchText = ""
    sortBy = "1"
    directionOfSorting = "1"

    hasError = false

    hasMsg = false
    
    errorMsg = ''

    email = ''

    password = ''

    password2 = ''

    //add product:
    newCode = ''
    newName = ''
    newDescription = ' '
    newPrice = 0.0
    newImage = ' '
    newShowPrice = true
    newIsAvailable = true

    categoryOptions = []
  
    newStars = 0
    newCount = 0
    newCategoryId = ''
    //end add product
    //add new category
    newCategory = ' '
    //end of new category

    countOfShopItems = 0
    countOfTotalPrice = 0.0

    countOfHistoryItems = 0
    countOfHistoryPrice = 0.0

    description = ''
    historyAll = []
    historyItems = []

    shoppingStatus = ''

    comments = ["Wow", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome", "awesome"]

    constructor() {
        // Call it here
        makeAutoObservable(this)
    }

    setDescription(val){
        this.description = val
    }

    getHistoryItemStatus(key){
        var slected_status = undefined

        this.historyAll.forEach(element => {
            if(element.key === key){
                slected_status = element.status
            }
        })

        return slected_status
    }

    updateHistoriesAdmin(key, status){
        this.historyAll.forEach(element => {
            if(element.key === key){
                element.updateStatus(status)
            }
        })
    }

    updateHistories(val){
        this.historyAll = []

        val.forEach(element => {
            this.historyAll.push(new History(element))
        })

        this.historyAll.sort(function(a,b){
            return new Date(b.created) - new Date(a.created);
          })
    }

    selectHistory(num){
        var history = null
        this.historyAll.forEach(element => {
            if(element.number === num){
                history = element
            }
        })

        var historyObj = JSON.parse(history.items)

        this.description = historyObj.description

        if(historyObj.items.length > 0){
            this.historyItems = []

            historyObj.items.forEach(element => {
                this.historyItems.push(new ShopItem(element))
            })  
        }

        this.updateHistoryItemsCount()        
    }

    updateHistoryItemsCount(){
        var formatterUA = new Intl.NumberFormat('ja-UA', {});

        var countOf = 0;
        var price = 0.0;

        this.historyItems.forEach(element => {
            countOf += element.count
            price += (element.price * element.count)
        });

        this.countOfHistoryItems = countOf
        this.countOfHistoryPrice = formatterUA.format(price)
    }

    updateShopItemsCount(){
        var formatterUA = new Intl.NumberFormat('ja-UA', {});

        var countOf = 0;
        var price = 0.0;

        this.shopItems.forEach(element => {
            countOf += element.count
            price += (element.price * element.count)
        });

        this.countOfShopItems = countOf
        this.countOfTotalPrice = formatterUA.format(price)
    }

    cleanShoppingList(){
        this.shopItems = []

        this.updateShopItemsCount()
    }

    addShopItem(id){
        var tempProduct = null

        this.products.forEach(element => {
            if(element.id === id){
                tempProduct = element
            }
        });

        if(!!tempProduct){
            var hasProduct = false

            this.shopItems.forEach(element => {
                if(element.id === id){
                    element.updateCount()

                    hasProduct = true
                }
            });

            if(!hasProduct){
                this.shopItems.push(new ShopItem(tempProduct))
            }
        }

        this.updateShopItemsCount()
    }

    removeShopItem(id){
        var removeAtIndex = -1

        for(var i = 0; i < this.shopItems.length; i++){
            if(this.shopItems[i].id === id){
                if(this.shopItems[i].count > 1){
                    this.shopItems[i].removeItem()
                }
                else{
                    removeAtIndex = i
                }
            }
        }

        if(removeAtIndex > -1){
            this.shopItems.splice(removeAtIndex, 1)
        }

        this.updateShopItemsCount()
    }

    setSearchText(val){
        this.searchText = val
    }

    setSortBy(val){
        this.sortBy = val
    }

    setDirectionOfSorting(val){
        this.directionOfSorting = val
    }

    setStaus(val){
        this.shoppingStatus = val
    }

    setSelectedCategory(val){
        this.selectedCategory = val
        this.newCategoryId = val
    }

    fromItem(page, itemsPerPage){
        return (page - 1) * itemsPerPage
    }
      
    toItem(page, itemsPerPage){
        return (page) * itemsPerPage + 1
    }

    itemOnPage(page, itemsPerPage) {//(items, page, itemsPerPage) {
        this.selectedProductsOnPage = []
        for(var i = this.fromItem(page, itemsPerPage); i < this.toItem(page, itemsPerPage)-1; i++){
          /*if(i < items.length)
            console.log(items[i])*/
            this.selectedProductsOnPage.push(this.selectedProducts[i])
        }
    }

    setSorting(){

    }

    setSearch(){
        
    }

    setCurrentPage(page){
        console.log(page)
        this.currentPage = page.activePage
        this.itemOnPage(this.currentPage, this.itemsPerPage)
    }

    getItemsFromPages(){
        this.itemOnPage(this.products, this.currentPage, this.itemsPerPage)
    }

    addProducts(objs){
        this.currentPage = 1
        this.products = []
        this.selectedProducts = []
        for(const item of objs){
            this.products.push(new Product(item.key, item))
            this.selectedProducts.push(new Product(item.key, item))
        }

        this.setSearchText("")
        this.setSortBy("3")
        this.setDirectionOfSorting("1")

        this.applySort()

        
    }

    editProductOf(id){
        var product = {} 

        this.products.forEach(element => {
            if(element.id === id){
                product = element
            }
        });

        this.newName = product.name
        this.newDescription = product.description
        this.newPrice = product.price
        this.newImage = product.image
        this.newShowPrice = product.show_price
        this.newIsAvailable = product.is_available
        this.newStars = product.stars
        this.newCategoryId = this.selectedCategory
    }

    updateProduct(product){
        this.products.forEach(element => {
            if(element.id === product.code){
                element.updateProduct(product)
            }
        });

        //this.applyChanges()
    }

    updateCategory(category){
        this.categories.forEach(element => {
            if(element.id === category.id){
                element.updateCategory(category)
            }
        });

        this.getOptions()
    }

    editCategoryOf(){
        var category = {} 

        this.categories.forEach(element => {
            if(element.id === this.selectedCategory){
                category = element
            }
        });

        this.newCategory = category.name
    }

    addCategoryTo(category){
        this.categories.push(new Category(category.key, category.name))
        this.getOptions()
    }

    deleteProduct(id){
        var tempArr = []
        this.products.forEach(element => {
            if(element.id !== id){
                tempArr.push(element)
            }
            else{
                console.log(`---DELETED ${id} ---`)
            }
        });
        this.products = tempArr

        this.applyChanges()

        if(this.currentPage >= this.lastPage){
            this.currentPage = 1
            this.applyChanges()
        }
    }

    addProductTo(product){
        this.products.push(new Product(product.code, product))

        this.applyChanges()
    }

    applyChanges(){
        if(this.directionOfSorting === "1"){
            if(this.sortBy === "1")
                this.products.sort((a, b) => (a.name > b.name) ? 1 : -1)

            if(this.sortBy === "2")
                this.products.sort((a, b) => (a.id > b.id) ? 1 : -1)

            if(this.sortBy === "3")
                this.products.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
        }
        else{
            if(this.sortBy === "1")
                this.products.reverse((a, b) => (a.name > b.name) ? 1 : -1)

            if(this.sortBy === "2")
                this.products.reverse((a, b) => (a.id > b.id) ? 1 : -1)

            if(this.sortBy === "3")
                this.products.sort((a, b) => -1*(parseFloat(a.price) - parseFloat(b.price)))
        }

        if(this.searchText.length > 0){
            var tempArr = []
            this.products.forEach(element => {
                if(element.name.includes(this.searchText) || element.id.includes(this.searchText)){
                    tempArr.push(element)
                }
            });
            this.selectedProducts = tempArr
        }
        else{
            this.selectedProducts = this.products
        }

        this.itemOnPage(this.currentPage, this.itemsPerPage)
         
        this.setLastPage()

    }

    applySort(){
        this.currentPage = 1

        this.applyChanges()
    }

    addCategories(objs){
        this.categories = []
        for(const item of objs){
            this.categories.push(new Category(item.key, item.name))
        }

        this.getOptions()
        
    }

    getOptions(){
        var options = []
        for(const item of this.categories){
          options.push({key:item.id, text: item.name, value: item.id})
        }
  
        this.categoryOptions = options
    }

    categoriesProductsLoaded(name){
        for(const item of this.categories){
            if(item.name === name){
                item.setLoaded(true)
                break
            }
        }
    }

    isProductsLoaded(name){
        var res = false
        for(const item of this.categories){
            if(item.name === name){
                res = item.loaded
                break
            }
        }
        return res
    }

    setLastPage(){
        this.lastPage = Math.ceil(this.selectedProducts.length/this.itemsPerPage)
    }

    setLastUpdate(update){
        this.lastUpdate = update
    }

    isUpTodate(current){
        return current === this.lastUpdate
    }

    setAdmin(newToken, verf){
        this.token = newToken
        this.isAdmin = true
        this.isVerified = verf
    }

    setUser(newToken, verf){
        this.token = newToken
        this.isAdmin = false
        this.isVerified = verf
    }

    setLogout(){
        this.token = ""
        this.isAdmin = false
        this.isVerified = false
    }

    setVerified(verf){
        this.isVerified = verf
    }

    updateCount() {
        this.likesCount++;
    }

    postComment(comment) {
        this.comments.push(comment)
    }

    postCommentOnTop(comment){
        this.newCommentCount++;
        this.comments.unshift(`${comment}${this.newCommentCount}`)
    }

    openLogin(){
        this.showLogin = true
        this.showMain = false
        this.showSingup = false
    }

    flipSingUpLogin(){
        var t = this.showLogin
        this.showLogin = this.showSingup
        this.showSingup = t

        this.showMain = false    
    }

    closeLogin(){
        this.showMsg(false)
        this.setErrorMsg('')
        this.showError(false)
        this.showLogin = false
        this.showMain = true
        this.showSingup = false
    }

    setDidMount(){
        this.didMount += 1
    }

    setLoading(load){
        this.isLoading = load
    }

    setToken(_token){
        this.token = _token;
    }

    showError(isError){
        this.hasError = isError;
    }

    showMsg(val){
        this.hasMsg = val;
    }

    setErrorMsg(text){
        this.errorMsg = text
    }

    setEmail(val){
        this.email = val
    }

    setPassword(val){
        this.password = val
    }

    setPassword2(val){
        this.password2 = val
    }

    //add new product:
    setNewCode(val){
        this.newCode = val
    }
    
    setNewName(val){
        this.newName = val
    }

    setNewDescription(val){
        this.newDescription = val
    }

    setNewPrice(val){
        this.newPrice = val
    }

    setNewImage(val){
        this.newImage = val
    }

    setNewShowPrice(val){
        this.newShowPrice = val
    }

    setNewIsAvailable (val){
        this.newIsAvailable = val
    }

    setNewCategory(val){
        this.newCategory = val
    }

    get commentsCount(){
        return this.comments.length;
    }

}


const storeInstance = new Store()

export default storeInstance;