import React, { useContext } from 'react'
import { Modal, Button, Form, Checkbox, Segment, Dimmer, Loader, Image, Icon, Message } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const ButtonExampleFluid = (prop) => {
    const store = useContext(StoreContext)

    const [open, setOpen] = React.useState(false)

    const handleDismiss = () => {
        store.showError(false)
        store.setErrorMsg('')
    }

    const handleMsgDismiss = () => {
        store.showMsg(false)
    }

    const handleShowPrice = (e, { name, value }) => {
        store.setNewShowPrice(value)
    }

    const handleIsAvailable = (e, { name, value }) => {
        store.setNewIsAvailable(value)
    }

    const handleNewName = (e, { name, value }) => {
        store.setNewName(value)
    }

    const handleNewDescription = (e, { name, value }) => {
        store.setNewDescription(value)
    }

    const handleNewPrice = (e, { name, value }) => {
        store.setNewPrice(value)
    }

    const handleNewImage = (e, { name, value }) => {
        store.setNewImage(value)
    }

    const closeWindow = () => {
        store.showMsg(false)
        setOpen(false)
    }

    const updateProduct = (id) => {
        (async () => {
            console.log(id)
          const rawResponse = await fetch(`https://e-shop.onrender.com/product/${id}`, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${store.token}`
            },
            body: JSON.stringify({
                "name": store.newName,
                "description": store.newDescription,
                "price": store.newPrice,
                "image": store.newImage,
                "show_price": store.newShowPrice,
                "is_available": store.newIsAvailable,
                "stars": store.newStars,
                "count": store.newCount,
                "category_id": store.newCategoryId
            })
          });
          const res = await rawResponse
          if(!res.ok){
            res.text().then(text => {
              console.log(text)
              var jsObj = JSON.parse(text)
              store.setErrorMsg(jsObj['detail'])
              store.showError(true)
            })

          }
          else{
            const content = await rawResponse.json();
            console.log(content);

            var product = {
                "code": id,
                "name": store.newName,
                "description": store.newDescription,
                "price": store.newPrice,
                "image": store.newImage,
                "show_price": store.newShowPrice,
                "is_available": store.newIsAvailable,
                "stars": store.newStars,
                "category_id": store.newCategoryId
            }

            store.updateProduct(product)
            store.showMsg(true)
          }
        })();
      }


    return useObserver(() => (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                store.editProductOf(prop.sid)
                setOpen(true)
            }}
            open={open}
            trigger={<Button icon labelPosition='left'>
                <Icon name='edit' />
                Змінити
            </Button>}
            >
            <Modal.Header>Оновити продукт</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                {store.isLoading &&
                    <Segment  vertical style={{ margin: '1em 0em 0em', padding: '5em 0em' }}>
                        <Dimmer active inverted>
                        <Loader size='large'>Завантаження</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/paragraph.png' />
                    </Segment>
                }
                {!store.isLoading &&
                <Form error>
                    {store.hasMsg &&
                        <Message
                            onDismiss={() => {handleMsgDismiss()}}
                            header='Успішно!!!'
                        />
                    }
                    {store.hasError &&
                        <Message
                            onDismiss={() => {handleDismiss()}}
                            error
                            header='Error'
                            content={store.errorMsg}
                        />
                    }
                    <Form.Field>
                        <label>Ім'я</label>
                        <Form.Input placeholder="Ім'я" value={store.newName} onChange={handleNewName}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Опис</label>
                        <Form.Input placeholder='Опис' value={store.newDescription} onChange={handleNewDescription}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Ціна</label>
                        <Form.Input placeholder='Ціна' value={store.newPrice} onChange={handleNewPrice}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Зображення</label>
                        <Form.Input placeholder='Зображення' value={store.newImage} onChange={handleNewImage}/>
                    </Form.Field>
                    <Form.Field>
                        <Checkbox 
                            label='показати ціну' 
                            checked={store.newShowPrice}
                            onChange={handleShowPrice}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox 
                            label='доступний' 
                            checked={store.newIsAvailable}
                            onChange={handleIsAvailable}
                        />
                    </Form.Field>
                </Form>
                }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => closeWindow()}//setOpen(false)}
                >
                Закрити
                </Button>
                <Button
                    content="OK"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => updateProduct(prop.sid)}//setOpen(false)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    ))
}

export default ButtonExampleFluid