import React, { useContext } from 'react'
import { Modal, Button, Form, Checkbox, Segment, Dimmer, Loader, Image, Icon, Message } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const ButtonExampleFluid = (prop) => {
    const store = useContext(StoreContext)

    const [open, setOpen] = React.useState(false)

    const handleDismiss = () => {
        store.showError(false)
        store.setErrorMsg('')
    }

    const handleMsgDismiss = () => {
        store.showMsg(false)
    }

    const handleNewName = (e, { name, value }) => {
        store.setNewCategory(value)
    }

    const closeWindow = () => {
        store.showMsg(false)
        setOpen(false)
    }

    const updateCategory = () => {
        (async () => {
            var id = store.selectedCategory
            console.log(id)
          const rawResponse = await fetch(`https://e-shop.onrender.com/category/${id}`, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${store.token}`
            },
            body: JSON.stringify({
                "name": store.newCategory,
            })
          });
          const res = await rawResponse
          if(!res.ok){
            res.text().then(text => {
              console.log(text)
              var jsObj = JSON.parse(text)
              store.setErrorMsg(jsObj['detail'])
              store.showError(true)
            })

          }
          else{
            const content = await rawResponse.json();
            console.log(content);

            var category = {
                "id": store.selectedCategory,
                "name": store.newCategory,
            }

            store.updateCategory(category)
            store.showMsg(true)
          }
        })();
      }


    return useObserver(() => (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                store.editCategoryOf()
                setOpen(true)
            }}
            open={open}
            trigger={<Button icon labelPosition='left'>
                <Icon name='edit' />
                Редагувати категорію
            </Button>}
            >
            <Modal.Header>Оновити категорію</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                {store.isLoading &&
                    <Segment  vertical style={{ margin: '1em 0em 0em', padding: '5em 0em' }}>
                        <Dimmer active inverted>
                        <Loader size='large'>Завантаження</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/paragraph.png' />
                    </Segment>
                }
                {!store.isLoading &&
                <Form error>
                    {store.hasMsg &&
                        <Message
                            onDismiss={() => {handleMsgDismiss()}}
                            header='Успішно!!!'
                        />
                    }
                    {store.hasError &&
                        <Message
                            onDismiss={() => {handleDismiss()}}
                            error
                            header='Error'
                            content={store.errorMsg}
                        />
                    }
                    <Form.Field>
                        <label>Ім'я</label>
                        <Form.Input placeholder="Ім'я" value={store.newCategory} onChange={handleNewName}/>
                    </Form.Field>
                </Form>
                }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => closeWindow()}
                >
                Закрити
                </Button>
                <Button
                    content="OK"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => updateCategory()}
                    positive
                />
            </Modal.Actions>
        </Modal>
    ))
}

export default ButtonExampleFluid