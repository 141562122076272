import React, { useContext } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const options = [
  { key: 'a', text: 'висхідний', value: '1' },
  { key: 'd', text: 'низхідний', value: '2' },
]

const DropdownExampleUpwardInline = () => {
  const store = useContext(StoreContext)

  const handleChange = (e, {value}) => {
    console.log(value)
    store.setDirectionOfSorting(value)
  }

  return useObserver(() => (
    <Dropdown fluid placeholder='Direction' selection onChange={handleChange} options={options} value={store.directionOfSorting}/>
  ))
  }

export default DropdownExampleUpwardInline