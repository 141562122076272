import React, {useContext} from 'react'
import { Button, Card, Image } from 'semantic-ui-react'
import CardExampleCardProps from './Card'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const CardExampleGroups = () => {
  const store = useContext(StoreContext)
  return useObserver(() => (
  <Card.Group>
      {store.selectedProductsOnPage.map((value, index) => {
        return <CardExampleCardProps card={value}/>
      })}
    
  </Card.Group>
  ))
}

export default CardExampleGroups