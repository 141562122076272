import React, { useContext } from 'react'
import { Pagination } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';

const PaginationExamplePagination = () => {
    const store = useContext(StoreContext)
    const handlePaginationChange = (e, { activePage }) => store.setCurrentPage({ activePage })
  
  return useObserver(() => (
  <Pagination 
    style={{ marginBottom: '1em'}} 
    activePage={store.currentPage}
    totalPages={store.lastPage} 
    onPageChange={handlePaginationChange}
    />
))}

export default PaginationExamplePagination