import React, {useContext}  from 'react'
import { Card, Button, Icon, Transition, Image, Header } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import UpdateButton from './UpdateButton';

const extra = (
  <a>
    <Icon name='user' />
    16 Friends
  </a>
)

const CardExampleCardProps = (prop) => { 
    console.log(prop.card)

    var state = { animation: 'pulse', duration: 500, visible: true }

    const addItemToShopList = (id) => {
      store.addShopItem(id);
    }

    const deleteById = (id) => {
        
        (async () => {
            console.log(`id = ${id}`)
          const rawResponse = await fetch(`https://e-shop.onrender.com/product/${id}`, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${store.token}`
            },
          });
          const res = await rawResponse
          if(!res.ok){
            res.text().then(text => {
              console.log(text)
              var jsObj = JSON.parse(text)
              store.setErrorMsg(jsObj['detail'])
              store.showError(true)
            })

          }
          else{
            //const content = await rawResponse.json();
            console.log(id);

            store.deleteProduct(id);
          }
        })();
      }
    
    const store = useContext(StoreContext)
    return useObserver(() => (
        <>
        {prop.card !== undefined &&
            <Card key={prop.card.id}>
            
                <Transition visible={true} animation='scale' duration={500}>
                    <Image src={prop.card.image} wrapped ui={false} />
                </Transition>
                
                <Card.Content>
                <Header as='h5'>{prop.card.name}</Header>
                <Card.Meta>
                    <span className='date'>{prop.card.id}</span>
                </Card.Meta>
                <Card.Description>
                    {prop.card.price > 0&&
                        <Header as='h3'><span>&#8372;</span>{prop.card.price}</Header>
                    }
                    
                </Card.Description>
                </Card.Content>
                {store.token.length > 0 && store.isVerified && store.isAdmin  &&
                  <Card.Content extra>
                      <UpdateButton sid = {prop.card.id} />
                      <Button 
                          negative 
                          floated='right'
                          onClick={() => deleteById(prop.card.id)}
                      >Видалити</Button>
                  </Card.Content>
                }
                {store.token.length > 0 && !store.isAdmin && 
                  <Card.Content extra>
                    <Button 
                      icon 
                      labelPosition='left'
                      onClick={() => addItemToShopList(prop.card.id)}
                    >
                      <Icon name='cart plus' />
                      Додати до картки
                    </Button>
                  </Card.Content>
                }
            </Card>
        }
        </>
))}

export default CardExampleCardProps