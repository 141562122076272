import React, { useEffect, useContext } from 'react'
import { Button, Icon, Label, Modal, Table, Segment, Input, TextArea, Form, Header, Image, Dimmer, Loader, Message } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import ShopHistoryItem from './ShopHistoryItem';

const MessageExampleDismissibleBlock = () => {
  const store = useContext(StoreContext)

  const [open, setOpen] = React.useState(false)

  const getShopHistories = () => {
    
    //401
    (async () => {
          var temp_itemps = JSON.stringify({description: store.description, items: store.shopItems})
          console.log(temp_itemps)

          var temp_url = `https://e-shop.onrender.com/price_list/`

          if(store.isAdmin){
            temp_url = 'https://e-shop.onrender.com/price_list/admin/'
          }

          const rawResponse = await fetch(temp_url, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${store.token}`
            },
          });
          const res = await rawResponse
          if(!res.ok){
            res.text().then(text => {
              console.log(text)
              var jsObj = JSON.parse(text)
              store.setErrorMsg(jsObj['detail'])
              store.showError(true)
            })

          }
          else{
            const content = await rawResponse.json();
            console.log(content);

            var histories = []            

            content.forEach(element => {
              if(store.isAdmin){
                histories.push({
                  "key": element.key,
                  "number": element.number,
                  "items": element.items,
                  "created": element.created_at,
                  "status": element.status,
                  "description": element.description,
    
                })
              }
              else{
                histories.push({
                  "number": element.number,
                  "items": element.items,
                  "created": element.created_at,
                  "status": element.status,
                  "description": element.description,
    
                })
              }
              
            })

            store.updateHistories(histories)

            store.showMsg(true)
          }
        })();
  }

  /*useEffect(() => {
    console.log('mount it!');
    //store.setLastUpdate(update)
    getShopHistories()

  }, []);*/

  const handleShopItems = () => {
    console.log('-shop-items-')
    getShopHistories()
  }

  const handleDismiss = () => {
    store.showError(false)
    store.setErrorMsg('')
  }

  const handleMsgDismiss = () => {
      store.showMsg(false)
  }

  const closeWindow = () => {
    store.showMsg(false)
    store.setDescription('')
    setOpen(false)
  }

  const showHistory = (id) => {
    
  }

  const getDate = (dat) => {
    const chars = dat.split('.');
    return chars[0]
  }

  

  return useObserver(() => (
    <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                store.editCategoryOf()
                setOpen(true)
            }}
            open={open}
            trigger={
              <Button as='a' inverted style={{margin: '0em 0.1em', }}
                onClick={() => {handleShopItems()}}
              >
                Історія
              </Button>
            }
            >
            <Modal.Header>Історія покупок</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                {store.isLoading &&
                    <Segment  vertical style={{ margin: '1em 0em 0em', padding: '5em 0em' }}>
                        <Dimmer active inverted>
                        <Loader size='large'>Завантаження</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/paragraph.png' />
                    </Segment>
                }
                {!store.isLoading &&
                <>
                  <Form error>
                      {store.hasError &&
                          <Message
                              onDismiss={() => {handleDismiss()}}
                              error
                              header='Error'
                              content={store.errorMsg}
                          />
                      }
                  </Form>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Номер</Table.HeaderCell>
                      <Table.HeaderCell>Створено</Table.HeaderCell>
                      <Table.HeaderCell>Статус</Table.HeaderCell>
                      <Table.HeaderCell>Дія</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {store.historyAll.map((value, index) => {
                      return <Table.Row>
                      <Table.Cell>
                        <Header as='h4' image>
                          <Header.Content>
                            {value.number}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>{getDate(value.created)}</Table.Cell>
                      {value.status === 'approved' &&
                        <Table.Cell positive>{value.status}</Table.Cell>
                      }
                      {value.status === 'canceled' &&
                        <Table.Cell negative>{value.status}</Table.Cell>
                      }
                      {(value.status !== 'canceled' && value.status !== 'approved') &&
                        <Table.Cell>{value.status}</Table.Cell>
                      }
                      
                      <Table.Cell>
                        <ShopHistoryItem num={value.number} sid={value.key}/>
                      </Table.Cell>
                    </Table.Row>
                    })}
                  </Table.Body>

                  
                </Table>
                </>
                  }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => closeWindow()}
                >
                Закрити
                </Button>     
            </Modal.Actions>
        </Modal>

  ))
}

export default MessageExampleDismissibleBlock