import React, { useContext, useEffect }  from 'react'
import { Button, Container, Segment, Dropdown, Menu, Icon } from 'semantic-ui-react'
import { StoreContext } from './index';
import { useObserver } from 'mobx-react';
import Cards from './Cards';
import Pages from './Pages';
import SortItem from './SortItem';
import Search from './Search';
import ApplyButton from './ApplyButton';
import AddButton from './AddButton';
import SortingDirection from './SortingDirection'
import MessageError from './MessageError';
import AddCategoryButton from './AddCategoryButton';
import UpdateCategoryButton from './UpdateCategoryButton';

const MainView = () => {
    const store = useContext(StoreContext)

    function sayHello() {
        store.postCommentOnTop('comment: ')
    }
    
    

    const loadC = (categoryID) => {
      store.isLoading = true
      fetch(`https://e-shop.onrender.com/product/category/${categoryID}`)
            .then((res) => {
              if(!res.ok){
                console.log("ERROR")
                store.isLoading = false
              }
                console.log(res.status)
                return res.json()
              })
            .then((json) => {
              console.log(json)
              store.addProducts(json)
              store.isLoading = false
      })
    
    }

    function getOptions(){
      var options = []
      for(const item of store.categories){
        options.push({key:item.id, text: item.name, value: item.id})
      }

      return options
    }

    const handleChange = (e, {value}) => {
      store.setSelectedCategory(value)
      loadC(value)
    }


    return useObserver(() => (
        
        <Container fluid style={{ margin: '5em 1em 1em 1em'}}>
          <Segment style={{ marginRight: '1em', marginLeft: '1em' }}>
              <Dropdown 
                fluid 
                placeholder='Категорії' 
                selection 
                options={store.categoryOptions} 
                onChange={handleChange}
                value = {store.selectedCategory}
                
              />
            {store.selectedCategory.length > 0 && store.token.length > 0 && store.isVerified && store.isAdmin  &&
             <Segment style={{ marginTop: '1em' }}>
               <AddCategoryButton />
               <UpdateCategoryButton />
             </Segment>
            }
          </Segment>
          <Segment.Group horizontal style={{ marginRight: '1em', marginLeft: '1em' }}>
            
            <Segment >
              <SortItem />
            </Segment>
            <Segment >
              <SortingDirection />
            </Segment>
            <Segment>
              <Search />
            </Segment>
            <Segment>
              <ApplyButton />
            </Segment>
          </Segment.Group>
          

          <Segment

            style={{ minHeight: 500, padding: '1em 1.5em 1em 1.5em' }}
            vertical
          >
            <Pages />
            {store.token.length > 0 && store.isVerified && store.isAdmin  &&
              <Segment style={{ marginRight: '1em', marginLeft: '1em' }}>
                <AddButton />
                {store.hasError &&
                  <MessageError />
                }
              </Segment>
            }
            <Cards />
          </Segment>
        
      </Container>
    ))}

export default MainView